import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';


const usePharmacy = () => {   

    const actions = {
        onSendCart: async(id, data2send) => {
            let result = null
            await private_server.post(`/pharmacy/send/${id}`, data2send)
        },
        onGetCartCountTotal: async(params) => {
            let request = await private_server.get(`/pharmacy/doctor/me/cartcounttotal`, {params:params})
            return request.data.data.cart_count
        }

    }

    return {
        actions
    }
}
 
export default usePharmacy;