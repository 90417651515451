//import { Button } from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useAuthContext } from '../../../auth/useAuthContext';
import NavigationTab from '../../../components/actions/NavigationTab';
import Iconify from '../../../components/iconify';
//import Iconify from '../../../components/iconify';
import PageLayout from '../../../layouts/PageLayout';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { onGetFullImagePath } from '../../../utils/general';
import AccountPasswordSection from './sections/AccountPasswordSection';
import GeneralAccountSection from './sections/GeneralAccountSection';
import useUserAccountPage from './useUserAccountPage';

const UserAccountPage = (props) => {
    
    
    const {profile} = props
    const {isdoctor} = useAuthContext()
    const {system, data, actions, form, passwordForm} = useUserAccountPage({profile})

    const {currentTab, file, account} = data
    const profileImg =  onGetFullImagePath(account?.image)
    const TABS = [
        {
          value: 'general',
          label: 'General',
          icon: <Iconify icon="ic:round-account-box" />,
          component: (
            <GeneralAccountSection 
                file={file || profileImg} form={form} loading={system.sending} 
                onAvatarDrop={actions.onAvatarDrop}
                onInputChange={actions.onInputChange}
                onSubmit={actions.onSubmit}
            />
          ),
        },
        {
          value: 'change_password',
          label: 'Seguridad',
          icon: <Iconify icon="ic:round-vpn-key" />,
          component: <AccountPasswordSection profile={profile} onReset={actions.onReset}
            form={passwordForm} loading={system.sending} 
            onInputChange={actions.onPasswordInputChange}
            onSubmit={actions.onUpdatePassword}
          />,
        },
    ];

    let _links = [{name:'Dashboard', href: isdoctor ? PATH_DASHBOARD.general.app : PATH_DASHBOARD.general.carts}]
    if(isdoctor) _links.push({name:'Usuarios', href:PATH_DASHBOARD.general.users})
    _links.push({name:'Cuenta', })

    return ( 
        <PageLayout stretch={true}
            title='Cuenta'
            title_seo='Mi cuenta | Celu'
            loading={system.loading}
            links={_links}
        >
            <div>
                <NavigationTab options={TABS} value={currentTab} onChange={actions.onChangeSection}/>
                {TABS.map(
                (tab) =>
                    tab.value === currentTab && (
                        <Box key={tab.value} sx={{ mt: 5 }}>
                            {tab.component}
                        </Box>
                    )
                )}
            </div>
        </PageLayout>
     );
}
 
export default UserAccountPage;