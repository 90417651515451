import { Tab, Tabs } from '@mui/material';
import React from 'react';

const NavigationTab = ({
    options, onChange, value
}) => {
    return ( 
        <Tabs value={value} onChange={(event, newValue) => onChange(newValue)}>
          {options.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
    );
}
 
export default NavigationTab;