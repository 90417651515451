import React, { useState } from 'react';
import { FormHelperText } from '@mui/material';
import UploadAvatar from '../upload/UploadAvatar';

const UploadAvatarForm = ({
  file, ...other
}) => {

    const [error, setError] = useState(null)

    const isError = Boolean(error)

    return (
        <div>
          <UploadAvatar
            accept={{
              'image/*': [],
            }}
            error={isError}
            file={file}
            {...other}
          />

          {isError && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
    );
}
 
export default UploadAvatarForm;