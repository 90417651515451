import React from 'react';
import {  Card, Grid, Stack, Typography } from '@mui/material';
import UploadAvatarForm from '../../../../components/forms/UploadAvatarForm';
import { fData } from '../../../../utils/formatNumber';
import InputForm from '../../../../components/forms/InputForm';
import ContainedButton from '../../../../components/actions/ContainedButton';
import InputSelectForm from '../../../../components/forms/InputSelectForm';

const GeneralAccountSection = ({
    onAvatarDrop, file, form, onInputChange, loading, onSubmit, onReset
}) => {
    return ( 
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                        <UploadAvatarForm
                            name="photoURL"
                            maxSize={3145728}
                            file={file}
                            onDrop={onAvatarDrop}
                            helperText={
                                <Typography
                                variant="caption"
                                sx={{
                                    mt: 2,
                                    mx: 'auto',
                                    display: 'block',
                                    textAlign: 'center',
                                    color: 'text.secondary',
                                }}
                                >
                                Archivos permitidos *.jpeg, *.jpg, *.png, *.gif
                                <br /> Tamaño máx. {fData(3145728)}
                                </Typography>
                            }
                        /> 
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ p: 3 }}>
                        <>
                            <Typography variant='subtitle2' sx={{paddingBottom:3}}>Información básica</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.first_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.fathers_last_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputForm data={form.mothers_last_name} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputSelectForm data={form.user_gender_id} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputForm data={form.email} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={4} md={3}>
                                    <InputSelectForm data={form.mobile_country_id} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={8} md={9}>
                                    <InputForm data={form.mobile} onChange={onInputChange} />
                                </Grid>
                                <Grid item xs={12} >
                                    <InputForm data={form.details} onChange={onInputChange} />
                                </Grid>
                            </Grid>
                        </>
                        
                        <Stack alignItems='flex-end' sx={{pt:3}}>
                            
                            <ContainedButton loading={loading} onClick={onSubmit}>
                                Guardar
                            </ContainedButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default GeneralAccountSection;