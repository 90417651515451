import { Suspense, lazy } from 'react';
import LoadingScreen from '../components/loaders/LoadingScreen';
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../layouts/Auth/SignInLayout/SignInLayout')));

// DASH

export const AppDash = Loadable(lazy(() => import('../pages/AppDash/AppDash')));


export const UsersListPage = Loadable(lazy(() => import('../pages/Users/UserListPage/UserListPage')));
export const BlogsListPage = Loadable(lazy(() => import('../pages/Blogs/BlogsListPage/BlogsListPage')));
export const AddBlogPage = Loadable(lazy(() => import('../pages/Blogs/AddBlogPage/AddBlogPage')));

export const AppointmentsListPage = Loadable(lazy(() => import('../pages/Appointments/AppointmentsListPage/AppointmentsListPage')));
export const AppointmentPage = Loadable(lazy(() => import('../pages/Appointments/AppointmentPage/AppointmentPage')));
export const AppointmentRoomPage = Loadable(lazy(() => import('../pages/Appointments/AppointmentRoomPage/AppointmentRoomPage')));
