// Shipping types
import noneCoverageIcon from '../assets/platform//icons/shipping_types/none.svg'
import celuDeliveryIcon from '../assets/platform/icons/shipping_types/celudelivery.svg'
import localDeliveryIcon from '../assets/platform/icons/shipping_types/local.svg'
import nationalDeliveryIcon from '../assets/platform/icons/shipping_types/national.svg'
// Payment types
import paypal from '../assets/platform/icons/payment_methods/paypal.png'
import mercadopago from '../assets/platform/icons/payment_methods/mercado_pago.png'
import card from '../assets/platform/icons/payment_methods/credit_card.png'
import cash_icon from '../assets/platform/icons/payment_methods/money.png'
import tpv_icon from '../assets/platform/icons/payment_methods/credit_card.png'

// Payment cards
import amex_logo from '../assets/platform/icons/credit_cards/amex_color.png'
import cb_logo from '../assets/platform/icons/cards/carte-bancaire.png'
import diners_logo from '../assets/platform/icons/cards/diners-club.png'
import discover_logo from '../assets/platform/icons/cards/discover.png'
import jcb_logo from '../assets/platform/icons/cards/jcb.png'
import mastercard_logo from '../assets/platform/icons/credit_cards/mastercard_color.png'
import visa_logo from '../assets/platform/icons/credit_cards/visa_color.png' 
import unionpay_logo from '../assets/platform/icons/cards/unionpay.png' 

// Order statuses icons
import receivedIcon from '../assets/platform/icons/order_statuses/received.svg'
import preparingIcon from '../assets/platform/icons/order_statuses/preparing.svg'
import inTransitIcon from '../assets/platform/icons/order_statuses/in_transit.svg'
import arrivedIcon from '../assets/platform/icons/order_statuses/arrived.svg'
import deliveredIcon from '../assets/platform/icons/order_statuses/delivered.svg'
import issueIcon from '../assets/platform/icons/order_statuses/issue.svg'

import errorOrderIcon from '../assets/platform/icons/order_statuses/error.svg'
import packageIcon from '../assets/platform/icons/order_statuses/package.svg'
import pendingOrderIcon from '../assets/platform/icons/order_statuses/pending.svg'

// Apointment types icons
import videocall_icon from '../assets/platform/icons/appointment_types/videocall.svg'
import call_icon from '../assets/platform/icons/appointment_types/call.svg'
import chat_icon from '../assets/platform/icons/appointment_types/chat.svg'

export const catalogs = {
    genders:[
        {value:1, label:'Hombre'},
        {value:2, label:'Mujer'},
        {value:3, label:'Otro'},
        {value:4, label:'Desconocido'},
    ],
    mobile_country_codes:[
        {value:1, label:'+52', country:'México', icon:null},
        {value:2, label:'+57', country:'Colombia', icon:null},
        {value:3, label:'+1', country:'Estados Unidos', icon:null},
    ],
    shipping_types:[
        {value:1, label:'Sin cobertura', icon:noneCoverageIcon, description:'Lo lamentamos, pronto estaremos contigo'},
        {value:2, label:'Celuenvío', icon:celuDeliveryIcon, description:'Estaremos pronto contigo'},
        {value:3, label:'Local', icon:localDeliveryIcon, description:'Menos de 24 horas'},
        {value:4, label:'Nacional', icon:nationalDeliveryIcon, description:'De 3 a 5 días hábiles'},
    ],
    payments_types:[
        {value:1, label:'Tarjeta de crédito/débito', img:card},
        {value:2, label:'Paypal', img:paypal},
        {value:3, label:'Mercado Pago', img:mercadopago},
        {value:4, label:'Efectivo', img:cash_icon},
        {value:5, label:'TPV', img:tpv_icon},
    ],
    order_statuses:[
        {value:1, svg:receivedIcon, color:'#CBB792', label:'Pendiente de pago', description:'Hemos recibido tu pedido', colorS:'#ffa000', icon:'report_problem'},
        {value:2, svg:pendingOrderIcon, color:'#AF78AD', label:'Pendiente', description:'Hemos recibido tu pedido', colorS:'#303f9f', icon:'local_shipping'},
        {value:3, svg:inTransitIcon, color:'#543081', label:'Enviado', description:'Tu pedido está en ruta', colorS:'#0097a7', icon:'local_shipping'},
        {value:4, svg:deliveredIcon, color:'#96AE76', label:'Entregado', description:'Tu pedido ha sido entregado', colorS:'#388e3c', icon:'check'},
        {value:5, svg:errorOrderIcon, color:'#D95D71', label:'Cancelado', description:'Tu pedido fue cancelado', colorS:'#d32f2f', icon:'error_outline'},
        {value:6, svg:issueIcon, color:'#D95D71', label:'Problema', description:'Ocurrió un problema con tu pedido, por favor contáctanos', colorS:'#f57c00', icon:'local_shipping'},
        {value:7, svg:issueIcon, color:'#D95D71', label:'Devuelto', description:'Ocurrió un problema con tu pedido, por favor contáctanos', colorS:'#cddc39', icon:'local_shipping'},
        {value:8, svg:issueIcon, color:'#D95D71', label:'Reembolsado', description:'Ocurrió un problema con tu pedido, por favor contáctanos', colorS:'#7b1fa2', icon:'local_shipping'},
        {value:9, svg:issueIcon, color:'#D95D71', label:'Problema de envío', description:'Hubo un problema con tu envío, lo estaremos reenviando de inmediato', colorS:'#006064', icon:'error_outline'},
        {value:10, svg:issueIcon, color:'#D95D71', label:'Problema con el pago', description:'Ocurrió un problema con el pago', colorS:'#ff6f00', icon:'error_outline'},
        {value:11, svg:packageIcon, color:'#6971B3', label:'Entrega pendiente', description:'El pedido pronto estará listo para ser recogido', colorS:'#ff6f00', icon:'local_shipping'},
    ],
    route_order_statuses:[
        {value:0, label:'Recibido', description:'¡Hemos recibido tu orden!', color:'rgb(203, 183, 146)', svg:receivedIcon},
        {value:1, label:'Preparando', description:'¡Estamos preparando tu pedido!', color:'rgb(175, 120, 173)', svg:preparingIcon},
        {value:2, label:'En camino', description:'¡Tu paquete ya está en camino', color:'rgb(84, 48, 129)', svg:inTransitIcon},
        {value:3, label:'¡Llegamos!', description:'¡Estamos en tu domicilio!', color:'rgb(105, 113, 179)', svg:arrivedIcon},
        {value:4, label:'Entregado', description:'', color:'rgb(150, 174, 118)', svg:deliveredIcon},
        {value:5, label:'Problema', description:'', color:'rgb(217, 93, 113)', svg:issueIcon    },
    ],
    appointment_types:[
        {value:1, label:'Videollamada', icon:videocall_icon},
        {value:2, label:'Llamada', icon:call_icon},
        {value:3, label:'Chat', icon:chat_icon},
    ],
    doctor_specialities:[
        {value:1, label:'Dermatología'},
        {value:2, label:'Medicina General'},
        {value:3, label:'Nutrición'},
        {value:4, label:'Psicología'},
    ],
    dosis_units:[
        {value:1, label:'Tableta(s)'},
        {value:2, label:'Cápsulas(s)'},
        {value:3, label:'Gota(s)'},
        {value:4, label:'Ampoyeta(s)'},
        {value:5, label:'Aplicación(es)'},
        {value:6, label:'G'},
        {value:7, label:'mg'},
        {value:8, label:'mcg'},
        {value:9, label:'UL'},
        {value:10, label:'ml'},
        {value:11, label:'cm3'},
    ],
    frequency_units:[
        {value:1, label:'Minuto(s)'},
        {value:2, label:'Hora(s)'},
        {value:3, label:'Día(s)'},
    ],
    duration_units:[
        {value:1, label:'Minuto(s)'},
        {value:2, label:'Hora(s)'},
        {value:3, label:'Día(s)'},
        {value:4, label:'Semana(s)'},
        {value:5, label:'Mes(es)'},
        {value:6, label:'Indefinido'},
    ],

}

export const CARDS = {
    'amex':{
        pattern:'**** **** **** ',
        logo:amex_logo,
    },
    'diners_club':{
        pattern:'**** **** **** ',
        logo:diners_logo,
    },
    'discover':{
        pattern:'**** **** **** ',
        logo:discover_logo,
    },
    'jcb':{
        pattern:'**** **** **** ',
        logo:jcb_logo,
    },
    'mastercard':{
        pattern:'**** **** **** ',
        logo:mastercard_logo,
    },
    'unionpay':{
        pattern:'**** **** **** ',
        logo:unionpay_logo,
    },
    'visa':{
        pattern:'**** **** **** ',
        logo:visa_logo,
    },
    'cartes_bancaires':{
        pattern:'**** **** **** ',
        logo:cb_logo,
    },
}
