import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthContext } from '../../../auth/useAuthContext';
import useForm from '../../../hooks/useForm';
import { PATH_AUTH } from '../../../routes/paths';


const useSignInLayout = () => {

    // Generic states
    const {login} = useAuthContext()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [show_password, setShowPassword] = useState(false)

    const formManager = useForm(form_data)
    const {form, setForm, onValidateForm, onGetFormData, onResetForm} = formManager

    useEffect(() => {
        onResetForm()
    },[])


    const actions = {
        onInitModule: () => {
            //const _form = onInitForm(form, null, content.form)
            //setLoading(false)
            //setSending(false)
            //setError(null)
            //setForm(JSON.parse(JSON.stringify(form_data)))
        },
        onRecoverPassword: () => {
            navigate(PATH_AUTH.resetPassword)
        },
        onToggleShowPassword: () => {
            let _form = {...form}
            let nextValue = !show_password
            _form.password.config ={..._form.password.config, type:nextValue ? 'text':'password'}
            setForm(_form)
            setShowPassword(nextValue)
        },
        handleKeyPress: (event) => {
            if (event.key === 'Enter') {
                actions.onSubmit();
            }
        },
        onClearError: () => {
            console.log('Limiar error')
            setError(null)
        },
        onSubmit: async() => {

            const valid = onValidateForm(form)
            
            if(!valid){
                setError('Debe llenar todos los campos solicitados')
                return
            }

            const data2send = onGetFormData(form)
            data2send.device = 'web'
            console.log(data2send)


            try {
                console.log('Iniciarlizar')
                setLoading(true)
                await login(data2send)
                //const response = await public_server.post(`/login`, data2send)
                //const token_data = response.data.data
                //await onSetTokenSession(token_data)
                //updateAuthorizationHeader(private_server)
                //const user_request = await private_server.get('/users/me')
                //const _cart =  await onGetPrivateCurrentCart('PRIVATE')
                //onUpdateUserData(user_request.data.data.user)
                //onUpdateCart(_cart)
                //setError(null)
                setLoading(false)

            } catch (error) {
                console.log('Mierror ', error)
                setError(error)
            }
            setLoading(false)

        }
    }

    return {
        loading, error, actions, show_password, formManager
    }
}

export default useSignInLayout

const form_data = {
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label:'Email',
            placeholder:'Ingresa tu correo electrónico',
            helperText:'Correo no válido'
        },
        rules: {type: 'email'}
    },
    password: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label:'Contraseña',
            placeholder:'Ingresa tu contraseña',
            helperText:'Contraseña incorrecta'
        },
        rules: {type: 'distance', min:1}
    },
}