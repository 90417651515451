import { Navigate, useRoutes } from "react-router"
import AuthGuard from "../auth/AuthGuard"
import GuestGuard from "../auth/GuestGuard"
//import SignInLayout from "../layouts/Auth/SignInLayout/SignInLayout"
import CompactLayout from "../layouts/CompactLayout"
import DashboardLayout from "../layouts/dashboard/DashboardLayout"
import Page404 from "../layouts/Mantainance/Page404/Page404"
import CartListPage from "../pages/Carts/CartList/CartListPage"
import { 
    LoginPage, 
    UsersListPage, 
    BlogsListPage,
    AddBlogPage, 
    AppointmentsListPage,
    AppointmentPage,
    AppointmentRoomPage,
    AppDash
} from "./elements"
import {Typography} from '@mui/material'
import UserAccountPage from "../pages/Users/UserAccountPage/UserAccountPage"
import ResetPasswordPage from "../pages/Auth/ResetPasswordPage/ResetPasswordPage"
import OrderListPage from "../pages/Orders/OrderList/OrderList"

const Router = () => {
    // Auth
    return useRoutes([
        // auth
        {
            path:'auth',
            children:[
                {
                    path:'login',
                    element: <GuestGuard>
                        <LoginPage />
                    </GuestGuard> 
                },
                {
                    path:'recover-password',
                    element: <ResetPasswordPage />
                },
            ]
        },
        // Dashboard
        {
            path:'dash',
            element: <AuthGuard>
                <DashboardLayout />
            </AuthGuard> ,
            children:[
                {
                    path:'app', element: <AppDash />
                },
                {
                    path:'users/profile', element: <UserAccountPage profile />
                },
                {
                    path:'users/:iduser/account', element: <UserAccountPage />
                },
                {
                    path:'users', element: <UsersListPage />
                },
                {
                    path:'carts', element: <CartListPage />
                },
                {
                    path:'orders',  element: <OrderListPage />
                },
                {
                    path:'blogs', element: <BlogsListPage />
                },
                {
                    path:'blogs/add', element: <AddBlogPage />
                },
                {
                    path:'appointments/:idappointment/room', element: <AppointmentRoomPage />
                },
                {
                    path:'appointments/:idappointment', element: <AppointmentPage />
                },
                {
                    path:'appointments', element: <AppointmentsListPage />
                },
                
            ]
        },
        // Generico
        {
            element: <CompactLayout />,
            children:[
                {
                    path:'404',
                    element: <Page404 />
                },
            ]
        },
        { path: '*', element: <Navigate to="/auth/login" replace /> },
    ])
}

export default Router