import { Box, Grid, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Stack } from '@mui/system';
import React, { useState, useEffect } from 'react';
import ContainedButton from '../../../components/actions/ContainedButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import InputForm from '../../../components/forms/InputForm';
import BaseModal from '../../../components/modals/BaseModal';
import useUser from '../../../hooks/api/useUser';
import useForm from '../../../hooks/useForm';
import { GENDERS, LANGUAGES } from '../../../utils/enums';
import moment from 'moment-timezone';
import CeluIcon from '../../../components/platform/CeluIcon';
import cartIcon from '../../../assets/platform/icons/communications/email.svg'
import useCart from '../../../hooks/api/useCart';
import usePharmacy from '../../../hooks/api/usePharmacy';
import { useSnackbar } from 'notistack';
const StyledRoot = styled('div')(({ theme }) => ({
    //right: 0,
    //bottom: 0,
    //zIndex: 9998,
    width: '100%',
    minHeight: '30vh',
    //position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  }));

const SendCartByEmail = (props) => {

    const {open, onClose, onRefresh, onCompleted, cart} = props
    const  classes = useStyles()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState()
    const cartManager = useCart()
    const controller = useForm(form_data)
    const {form, onChangeForm, onResetForm, onGetFormData} = controller
    const pharmacyManager = usePharmacy()
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if(open) onResetForm()
    },[open])

    const onSubmit = async() => {


        setSending(true)
        const data2send = onGetFormData()

        try {
            console.log('Send email data', data2send)
            await pharmacyManager.actions.onSendCart(cart?.id_cart, data2send)
            //const response = await cartManager.actions.onAddTemplate(data2send)
            //console.log(response)
            //await onCompleted({id_cart:response})
            enqueueSnackbar("Correo enviado exitosamente", {variant:"success"})
            onClose()

        } catch (error) {
            setError(error)
            setSending(false)
            return
        }

        setSending(false)
        

    }

    return ( 
        <BaseModal maxWidth='xs'
        title=''
        open={open} 
        onClose={onClose} >
            <StyledRoot>
                <div>
                    <Stack alignItems='center' spacing={2}>
                        <div>
                            <CeluIcon size='large' icon={cartIcon}/>
                        </div>
                        <Typography variant='h6' align='center' sx={{maxWidth:300}} >
                            Ingresa un correo electrónico
                        </Typography>
                        <Typography variant='body2' align='center'  >
                            Se enviará las intrucciones para agregar el carrito a la cuenta de correo que ha proporcionado
                        </Typography>
                        <Box sx={{marginTop:4, marginBottom:16, width:'100%'}}>
                            <InputForm data={form.email} onChange={onChangeForm}/>
                        </Box>
                        {error && 
                            <ErrorAlert 
                            error={error} 
                            onClose={() => setError(null)} />
                        }
                        <ContainedButton disabled={!form?.email?.isValid} loading={sending} onClick={onSubmit}>
                            Continuar
                        </ContainedButton>
                    </Stack>
                </div>
            </StyledRoot>
        </BaseModal>
     );
}

const useStyles = makeStyles(theme => ({
    description:{
        marginBottom:16
    }
}))
 
export default SendCartByEmail;

const form_data = {
    email: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'text',
            fullWidth: true,
            label:'Correo electrónico',
            placeholder:'Correo electrónico',
            helperText:'Debe de contener de 1 a 255 caracteres '
        },
        rules: {type: 'email', min:1, max:255}
    },
}

