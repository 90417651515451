import React from 'react'
import Image from '../../../../components/images/Image';
import background from '../../../../assets/platform/celu-bg-vert.jpg';
import { Box, Card, useMediaQuery } from '@mui/material';

const BannerVerticalPharma = () => {


    return ( 
        <Box>
            <Card
                sx={{
                    borderRadius:2,
                }}
            >
                <Image src={background} ratio='3/4' />
            </Card>
        </Box>
     );
}
 
export default BannerVerticalPharma;