import { useCallback, useEffect, useState } from "react"
import { useAuthContext } from "../../../auth/useAuthContext"
import { useSnackbar } from '../../../components/snackbar';
import { CONFIG_SETTINGS } from "../../../config";
import useCart from "../../../hooks/api/useCart";
import useTableSettings from "../../../hooks/useTableSettings";
import { sleep } from "../../../utils/general";

const useCartListPage = () => {

    const { pharmacy} = useAuthContext()
    const { enqueueSnackbar } = useSnackbar();


    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [cart_loading, setCartLoading] = useState(false)
    const [error, setError] = useState(false)
    const [cart, setCart] = useState()
    const [templates, setTemplates] = useState([])
    const [count, setCount] = useState(0)
    const [view_cart, setViewCart] = useState(false)

    const [modals, setModals] = useState({
        add_cart:false,
        delete_user:false,
        add_product_cart:false,
        assign_user_cart:false,
        send_marketing_cart:false
    })


    const cartManager = useCart()

    const tableController = useTableSettings({
        initial_filter:{
            search:{key:'alias', value:'', operator:'LIKE'},
            start_date:{key:'', value:'', operator:'>='},
            end_date:{key:'', value:'', operator:'<='}
        },
        initial_params:{
            order_by:'id_cart',
            order:1,
            limit:25, 
            offset:0,
        }
    })

    useEffect(() => {
        //console.log(pharmacy)
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onRefreshData()
    }, [tableController.pagination, tableController.filter])

    const onGetParams = useCallback(() => {
        let result = {...tableController.pagination}
        let ANDargs = []
        Object.keys(tableController.filter).forEach(key => {
            const item = tableController.filter[key]
            if(item.value){
                if(item.operator === 'LIKE'){
                    ANDargs.push({field:item.key, operator:item.operator, value:`%${item.value}%`})
                }else{
                    ANDargs.push({field:item.key, operator:item.operator, value:item.value})
                }
            }
        })
        if(ANDargs.length){
            const temp = `${JSON.stringify({"AND":ANDargs})}`
            result.filter = temp
        }
        
        return result
    },[tableController.pagination])

    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                const params = onGetParams()
                const _templates = await cartManager.actions.onGetAllTemplates(pharmacy?.id_pharmacy, params)
                setTemplates(_templates.data)
                setCount(_templates.count)
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRefreshData: async() => {
            setSending(true)
            try {
                const params = onGetParams()
                const _templates = await cartManager.actions.onGetAllTemplates(pharmacy?.id_pharmacy, params)
                setTemplates(_templates.data)
                setCount(_templates.count)
                console.log('Mi cart', cart)
                if(cart){
                    try {
                        const _cart = await cartManager.actions.onGetCart(cart?.id_cart)
                        setCart(_cart)
                    } catch (error) {
                        
                    }
                    
                }
            } catch (error) {
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setSending(false)
        },
        onSelectRow: async(row) => {
            setCartLoading(true)
            setCart(row)
            setViewCart(true)
            try {
                const _cart = await cartManager.actions.onGetCart(row?.id_cart)
                setCart(_cart)
                
            } catch (error) {
                console.log(error)
            }
            setCartLoading(false)
        },
        onEditRow: async(row) => {
            setCartLoading(true)
            setCart(row)
            try {
                const _cart = await cartManager.actions.onGetCart(row?.id_cart)
                setCart(_cart)
                actions.onChangeModalStatus('edit_cart', true)
            } catch (error) {
                console.log(error)
            }
            setCartLoading(false)
        },
        onDeleteRow: async(row) => {
            console.log('Emlimianr', row)
            setLoading(true)
            try {
                await cartManager.actions.onDeleteCart(row.id_cart)
                setCart(null)
                setViewCart(false)
                enqueueSnackbar('Carrito eliminado exitosamente', {variant:"success"})
                await sleep(300)
                await actions.onRefreshData()
            } catch (error) {
                console.log(error)
                setError(error)
                enqueueSnackbar(error?.message, {variant:"error"})
            }
            setLoading(false)
        },
        onRequestSearch: (value) => {
            console.log(value)
            tableController.onUpdateFilterValue('search', value)
        },
        onCloseCartDetails: () => {
            setViewCart(false)
            setCart(null)
        },
        onChangePagination: (_data) =>{
            tableController.setPagination(_data)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        }, 
        onGetLink: async() => {
            try {
                const qrCodeData = `${CONFIG_SETTINGS.HOST_URL}/cart?template_id=${cart?.id_cart??''}`.replace('#','_')
                await navigator.clipboard.writeText(qrCodeData)
                enqueueSnackbar("Link copiado con éxito", {variant:"success"})
            } catch (error) {
                
            }
        }
    }


    const table_config = tableController.pagination

    const system = {loading, sending, error, cart_loading}
    const data = {table_config, count, templates, cart, view_cart}

    return {
        system, data, actions, modals
    }
}
 
export default useCartListPage;