import React, { useState } from 'react';
import { Card, Grid, Stack, Typography } from '@mui/material';
import InputForm from '../../../../components/forms/InputForm';
import ContainedButton from '../../../../components/actions/ContainedButton';
import ShowPasswordBtn from '../../../../components/actions/ShowPasswordBtn';

const AccountPasswordSection = ({
    form, onInputChange, loading, onSubmit, profile, onReset
}) => {


    const [view, setView] = useState({
        previous_password:false,
        new_password:false,
        passwordConfirmationProfile:false,
        password:false,
        passwordConfirmation:false
    })

    const onChange = (key) => {
        let temp = {...view}
        temp[key] = !temp[key]
        setView(temp)
    }


    form.previous_password.config = {...form.previous_password.config, type: !view.previous_password ? 'password' : 'text'}
    form.new_password.config = {...form.new_password.config, type: !view.new_password ? 'password' : 'text'}
    form.passwordConfirmationProfile.config = {...form.passwordConfirmationProfile.config, type: !view.passwordConfirmationProfile ? 'password' : 'text'}
    form.password.config = {...form.password.config, type: !view.password ? 'password' : 'text'}
    form.passwordConfirmation.config = {...form.passwordConfirmation.config, type: !view.passwordConfirmation ? 'password' : 'text'}


    return ( 
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card sx={{ p: 3 }}>
                        <>
                            <Typography variant='subtitle2' sx={{paddingBottom:3}}>Actualizar contraseña</Typography>
                            <Grid container spacing={3}>
                                {profile && <Grid item xs={12} >
                                    <InputForm data={form.previous_password} onChange={onInputChange} 
                                        endAdornment={<ShowPasswordBtn 
                                            show={view.previous_password} 
                                            onChange={() => onChange('previous_password')} />
                                        }
                                    />
                                </Grid>}
                                {profile && <Grid item xs={12} >
                                    <InputForm data={form.new_password} onChange={onInputChange} 
                                        endAdornment={<ShowPasswordBtn 
                                            show={view.new_password} 
                                            onChange={() => onChange('new_password')} />
                                        }
                                    />
                                </Grid>}
                                {!profile && <Grid item xs={12} >
                                    <InputForm data={form.password} onChange={onInputChange} 
                                    endAdornment={<ShowPasswordBtn 
                                        show={view.password} 
                                        onChange={() => onChange('password')} />
                                    }
                                    />
                                </Grid>}
                                {profile && <Grid item xs={12} >
                                    <InputForm data={form.passwordConfirmationProfile} onChange={onInputChange} 
                                        endAdornment={<ShowPasswordBtn 
                                            show={view.passwordConfirmationProfile} 
                                            onChange={() => onChange('passwordConfirmationProfile')} />
                                        }
                                    />
                                </Grid>}
                                {!profile && <Grid item xs={12} >
                                    <InputForm data={form.passwordConfirmation} onChange={onInputChange} 
                                        endAdornment={<ShowPasswordBtn 
                                            show={view.passwordConfirmation} 
                                            onChange={() => onChange('passwordConfirmation')} />
                                        }
                                    />
                                </Grid>}
                            </Grid>
                        </>
                        
                        <Stack alignItems='flex-end' sx={{pt:3}}>
                            <ContainedButton loading={loading} onClick={onSubmit}>
                                Actualizar
                            </ContainedButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default AccountPasswordSection;