import React from 'react';
import { alpha, CircularProgress, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cx from 'classnames'
import Iconify from '../../iconify';

const max = 20

const AddCartButton = ({loading, quantity = 0, onChangeQuantity}) => {
    
    const theme = useTheme()
    const classes = useStyles()    

    //console.log(quantity)

    const onAddItem = () => {
        if(loading) return
        let temp = quantity + 1
        if(temp > max) temp=max
        onChangeQuantity(temp)
    }

    const onRemoveItem = () => {
        if(loading) return
        let temp = quantity - 1
        if(temp < 1){
            onChangeQuantity(0)
            //onDeleteItem()
            return
        }
        onChangeQuantity(temp)
        
    }


    
    return ( 
        <div className={classes.root}>
            <div className={cx({
                [classes.selector]:true,
                [classes.hide_selector]:quantity === 0
            })}>
                <div className={classes.selector_container}>
                    <div className={cx(classes.icon_button, classes.transparent_button)} onClick={onRemoveItem}>
                        <Iconify icon='ic:baseline-remove' 
                        sx={{width:24, height:24, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}
                        />
                    </div>
                    <div style={{flex:1}}/>
                    <div className={classes.icon_button} style={{display:quantity > 0 ?'inherit' : 'none'}} onClick={onAddItem}>
                        <Iconify icon='carbon:add' 
                        sx={{width:24, height:24, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)'}}
                        />
                    </div>
                </div>
                <Typography className={classes.quantity}  style={{display:quantity > 0 ?'inherit' : 'none'}}>{quantity}</Typography>
            </div>
            <div className={classes.button} onClick={onAddItem}>
                {loading ? <div className={classes.loading}><CircularProgress size={32} color='secondary' style={{color:'white'}} /></div> : null}
                <Iconify icon='carbon:add' 
                        sx={{width:24, height:24, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', color:'white'}}
                        />
            </div>
        </div>
        
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        //background:'red'
    },
    button:{
        background:theme.palette.primary.main,
        width:32,
        height:32,
        borderRadius:'50%',
        position:'relative',
        cursor:'pointer'
    },
    loading:{
        position:'absolute',
        top:0, left:0, zIndex:3,
        width:'100%',
        height:'100%',
        //background:'red'
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)'
    },
    selector:{
        position:'absolute',
        top:0,
        right:0,
        background:theme.palette.primary.light,
        width:104,
        height:32,
        borderRadius:32,
        zIndex:1,
        transition:theme.transitions.create(['width'], {
            duration:theme.transitions.duration.leavingScreen,
            easing:theme.transitions.easing.easeOut,
        })
    },
    hide_selector:{
        width:32,
        zIndex:0,
        transition:theme.transitions.create(['width'], {
            duration:theme.transitions.duration.leavingScreen,
            easing:theme.transitions.easing.easeOut,
        })
    },
    selector_container:{
        position:'relative',
        display:'flex',
    },
    quantity:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        fontSize:14,
        fontWeight:600

    },
    icon_button:{
        background:theme.palette.primary.main,
        color:'white',
        width:32,
        height:32,
        borderRadius:'50%',
        position:'relative',
        cursor:'pointer',
        '&:hover':{
            background:theme.palette.primary.dark
        }
    },
    transparent_button:{
        background:'transparent',
        color:theme.palette.text.primary,
        '&:hover':{
            background:alpha('#000',0.1)
        }
    },
    icon:{
        //position:'absolute',
        //top:'50%', left:'50%',
        //transform:'translate(-50%, -50%)',
        //fontSize:18
    },
    iconSingle:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -75%)',
        //fontSize:18
    }
}))
 
export default AddCartButton;