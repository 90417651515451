import { IconButton } from '@mui/material';
import React from 'react';
import Iconify from '../iconify';

const ShowPasswordBtn = ({
    show, onChange
}) => {
    return ( 
        <>
            <IconButton onClick={onChange}>
                <Iconify icon={show ? 'el:eye-close' : 'el:eye-open'}/>
            </IconButton>
        </>
     );
}
 
export default ShowPasswordBtn;