import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
//import AddCartButton from '../../Actions/AddCartButton';
import PriceContainer from './PriceContainer';
import ProductName from './ProductName';
import DiscountChip from './DiscountChip';
import ProductImage from './ProductImage';
import { makeStyles } from '@mui/styles';
import AddCartButton from './AddCartButton';

const OrderedProduct = (props) => {

    const {
        product,
        loading,
        quantity, 
        onSelectedProduct, 
        onChangeQuantity,
        image_xs,
        disabledPrice, 
        disabledOffer
    } = props

    const classes = useStyles()

    const _image_xs = image_xs??6
    const _content_xs = 12 - _image_xs

    return ( 
        <div >
            <Grid container spacing={2}>
                <Grid item>
                    <Card className={classes.card} onClick={() => onSelectedProduct(product)}>
                        {!disabledOffer && <div className={classes.discount}>
                            <DiscountChip product={product} small/>
                        </div>}
                        <ProductImage
                            fake={product?.fake}
                            src={(product?.image_web || product?.main_product_image_web) ?? (product?.image_mobile || product?.main_product_image_mobile) }
                            ratio='1/1'
                        />
                        
                    </Card>
                </Grid>
                <Grid item xs>
                    <div className={classes.right_container}>
                        <div>
                            <Grid container>
                                <Grid item xs>
                                    <ProductName product={product}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' color='textSecondary'>{`x${product?.quantity ?? 0}`}</Typography>
                                </Grid>
                            </Grid>
                            
                            <PriceContainer product={product} />
                        </div>
                    </div>
                    
                </Grid>
            </Grid>
            
            
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        borderRadius:16,
        padding:8,
        position:'relative',
        cursor:'pointer',
        width:90
    },
    discount:{
        position:'absolute',
        top:4, left:4,
        zIndex:2
    },
    title:{
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 1,
        wordBreak: "break-all",
        overflow: "hidden"
    },
    price_container:{
        display:'flex',
        alignItems:'center'
    },
    old_price:{
        color:grey[500],
        textDecoration:'line-through',
        marginLeft:8,
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.750rem'
        }
    },
    right_container:{
        position:'relative',
        //border:'1px solid black',
        height:'100%'
    },
    add_button_container:{
        marginTop:8
        //position:'absolute',
        //bottom:8, right:0,
    }
}))
 
export default OrderedProduct;