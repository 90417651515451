import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Stack, TableRow, MenuItem, TableCell, IconButton, Typography } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';
import { onGetDateWithTimezone } from '../../../../utils/formatTime';
//import { onGetCurrencyValue } from '../../../../utils/currencies';
import ContainedButton from '../../../../components/actions/ContainedButton';

// ----------------------------------------------------------------------

CartTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function CartTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { id_cart, seller, creation_date, alias } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow} padding="checkbox">
            <Typography variant="subtitle2" noWrap>
              {id_cart}
            </Typography>
        </TableCell>
        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow} >
            <Typography variant="subtitle2" noWrap>
              {alias ?? '-'}
            </Typography>
        </TableCell>

        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="subtitle2" noWrap>
              {seller}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}align="left">{onGetDateWithTimezone(creation_date, 'DD MMM YY, HH:mm')??'-'}</TableCell>

        {/*<TableCell sx={{cursor:'pointer'}} onClick={onSelectRow}align="left">
          {onGetCurrencyValue(payment_subtotal)}
  </TableCell>*/}


        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover open={openPopover} onClose={handleClosePopover} arrow="right-top" sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow(row);
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar carrito"
        content="¿Está seguro de eliminar este carrito? Ya no podrá recuperarse en un futuro"
        action={
          <ContainedButton size='small' onClick={onDeleteRow}>
            Eliminar
          </ContainedButton>
        }
      />
    </>
  );
}
