import React from 'react';
import SVGIcon from '../images/SVGIcon';
import logo from '../../assets/company/logo.svg'
import {Typography, Stack} from '@mui/material'


const Logo = ({
    size,
    enableText, 
    textVariant
}) => {
    return ( 
        <div>
            <Stack direction='row' spacing={1} alignItems='center'>
                <SVGIcon src={logo} size={size} color='#543081' />
                {enableText ? <Typography variant={textVariant ?? 'h4'} >celu</Typography> : null}
            </Stack>
            
        </div>
     );
}
 
export default Logo;