import { useState, useCallback } from 'react';
import { onGetDateWithTimezone } from '../utils/formatTime';
import { isFormValid, onSetErrorsToForm } from '../utils/forms';


const useForm = (formData) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const onResetForm = useCallback((settings) => {
        if(formData){
            let _form = JSON.parse(JSON.stringify(formData))
            if(settings?.catalogs){
                settings?.catalogs?.forEach(item => {
                    _form[item?.key].options = item?.options??[]
                })
            }
            setForm(_form)
        }
    },[formData])

    const onInitForm = useCallback((origin, settings) => {
        //if(!origin) return
        //console.log(formData)
        if(formData){
            let temp = JSON.parse(JSON.stringify(formData)) 
            Object.keys(temp).forEach(item => {
                if(origin){
                    let value = origin[item]
                    if(temp[item]?.config?.type === 'date'){
                        value = onGetDateWithTimezone(value, 'YYYY-MM-DD')
                    }
                    if(temp[item]?.config?.type === 'checkbox' || temp[item]?.config?.type === 'switch'){
                        value = value ? 1 : 0
                    }
                    if(value) temp[item] = {
                        ...temp[item], 
                        value:value, 
                        isVisited:true, 
                        isValid:true}
                }
                
            })
            //console.log(origin,'orignal', settings, 'settings')
            if(settings?.catalogs){
                settings?.catalogs?.forEach(item => {
                    const _options = item?.options??[]
                    temp[item?.key].options = item?.options??[]
                    if(item.forceValue && _options.length !== 0){
                        temp[item?.key].value = item?.value ? item?.value : _options[0]

                    }
                    
                })
            }
            setForm(temp)
        }
        //console.log(temp)
    },[formData])

    const onChangeForm = useCallback((data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(data.injectData){
            temp[data.injectData].compareValue = data.value
        }
        setForm(temp)
    },[form])

    const onValidateForm = useCallback(() => {
        let isValid = true
        const errors = isFormValid(form)
        //console.log(form, errors)
        if(errors && errors.length){
            const _form = onSetErrorsToForm(form, errors)
            setForm(_form)
            isValid = false
        }
        console.log(errors)
        return isValid
    },[form])

    const onGetFormData = useCallback((settings) => {
        //console.log(settings)
        const origin = settings?.origin ?? null
        const keys = settings?.keys ?? null
        const removeEmptySpaces = settings?.removeEmptySpaces ?? null
        const eraseEmptySpaces = settings?.eraseEmptySpaces ?? null

        let temp = {}
        
        if(origin){
            if(keys && keys.length){
                
                keys.forEach(item => {
                    const autocomplete = form[item]?.config?.type === 'autocomplete'
                    if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]: autocomplete ? form[item].value?.value : form[item].value}
                })
            }else{
                Object.keys(form).forEach(item => {
                    const autocomplete = form[item]?.config?.type === 'autocomplete'
                    if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]: autocomplete ? form[item].value?.value : form[item].value}
                })
            }
        }else{
            if(keys && keys.length){
                keys.forEach(item => {
                    const autocomplete = form[item]?.config?.type === 'autocomplete'
                    if(form[item].isValid) temp = {...temp, [item]: autocomplete ? form[item].value?.value : form[item].value}
                })
            }else{
                Object.keys(form).forEach(item => {
                    const autocomplete = form[item]?.config?.type === 'autocomplete'
                    if(form[item].isValid) temp = {...temp, [item]: autocomplete ? form[item].value?.value : form[item].value}
                })
            }
        }
        //console.log(removeEmptySpaces) 
        if(removeEmptySpaces){
            Object.keys(temp).forEach(item => {
                if(!Boolean(temp[item])) temp[item] = undefined
            })
        }

        if(eraseEmptySpaces){
            Object.keys(temp).forEach(item => {
                if(temp[item] === undefined || temp[item] === null || temp[item] === '' ) temp[item] = '@null!!'
            })
        }

        return temp
    },[form])

    const onUpdateDBErrors = useCallback((data) => {
        try {
            if(!data) return
            let temp = {...form}
            Object.keys(data).forEach(item => {
                temp[item].isValid = false
                temp[item].isVisited = true
            })
            setForm(temp)
        } catch (error) {
            console.log(error)
        }
        
    },[form])

    return {
        form,
        setForm,
        onResetForm,
        onGetFormData,
        onChangeForm,
        onValidateForm,
        onInitForm,
        onUpdateDBErrors
    }
}

export default useForm