import { private_server, public_server } from "./axios"
import { PRODUCT_DISCOUNT_TYPES, CURRENCY } from "./enums"

export const onGetProductItemPrice = (product) => {
    let result = {
        current:product?.price??'',
        previous:null,
        free:0,
    }
    //console.log('PRICE ANALYSE', product)
    const tax_lines = product?.taxable === 1 ? (product?.product_taxes || product?.tax_lines) : []
    const product_quantity = parseInt(product?.quantity || product?.product_quantity)
    //console.log('product_quantity',product_quantity)
    const _product_discount_type_id = product?.product_discount_type_id || product?.sale_discount_type_id
    //console.log('product_discount_type',_product_discount_type_id)
    if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.DISCOUNT){
        //console.log('DISCOUNT')
        result.current = parseFloat(product?.sale_price)
        result.previous = parseFloat(product?.price)
        result.free = 0

    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE){
        //console.log('BUNDLE')            
        if(product_quantity >= product.sale_bundle_quantity){
            result.current = product?.sale_bundle_price
            result.previous = product?.price
            result.free = 0
        }
    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){
        //console.log('BUNDLE_QXQ')        
        //const freeItems = product?.sale_bundle_included_free
        result.current = product?.price
        result.previous = null
        result.free = product?.sale_bundle_included_free || product?.sale_bundle_free_quantity
    }

    let current_tax_sum = 0
    let previous_tax_sum = 0
    if(product?.taxable === 1){
        tax_lines.forEach(item => {
            let _tax = parseFloat(item?.rate??0.00)
            if(_tax > 1) _tax = _tax/100
            current_tax_sum += result.current*(parseFloat(_tax))
            previous_tax_sum += result.previous*(parseFloat(_tax))
        })
    }

    result.current = parseFloat(result.current) + current_tax_sum
    result.previous = parseFloat(result.previous) + previous_tax_sum

    return result

}

export const isProductAlreadyInCart = (product, line_items, key) => {
    const _key = key ? key : 'product_id' 
    if(!line_items) return null
    if(!Boolean(line_items.length)) return null
    if(!product) return null
    const cart_product = line_items.find(el => el.product_id === product[_key])
    if(!cart_product) return null
    return cart_product

}

export const onGetCartId = async(cart, user) => {
    let cart_id = null
    let type = 'PUBLIC'
    if(user){
        //console.log('There is a user')
        type = 'PRIVATE'
        if(!cart){
            const create_cart_request = await private_server.post(`/cart/me`, {currency_id:CURRENCY.MXN})
            cart_id = create_cart_request.data.data.cart_id           
        }else{
            cart_id = cart.id_cart
        }
    }else{
        //console.log('There is no user')
        const _public_cart_id = localStorage.getItem('public_cart_id')
        if(_public_cart_id){
            //console.log('There is a public cart id')
            cart_id = _public_cart_id
        }else{
            //console.log('There is no public cart id')
            const create_cart_request = await public_server.post(`/cart/public`, {currency_id:CURRENCY.MXN})
            console.log(create_cart_request.data.data)
            cart_id = create_cart_request.data.data.cart_id     
            localStorage.setItem('public_cart_id', cart_id) 
        }
    }

    return {cart_id, type}
}   

///////////////////// SERVER HANDLERS ///////////////////////////
export const updateAuthorizationHeader = (server) => {
    const token = localStorage.getItem('accessToken');
    if(!token){
        console.log('Token not valid')
        return
    }
    server.defaults.headers.common['Authorization'] = token;
}


export const onAddRemoveProductToCart = async(cart, product, quantity, line_item, user,product_key) => {
    const key = product_key ? product_key : 'product_id'
    let updated_cart, cart_request
    updateAuthorizationHeader(private_server)
    let data2send = {product_id:product[key]}

    ////////////////////  GET CART ID /////////////////////////////////////////
    const {cart_id, type} = await onGetCartId(cart, user)
    const id_cart = cart_id

    ////////////////////  ADD REMOVE LINE ITEM AFTER GETTING CART ID /////////////////////////////////////////
    if(!line_item){
        console.log('Need to post a line item')
        if(type === 'PRIVATE'){
            data2send.quantity = quantity
            await private_server.post(`/lineitem/me/${id_cart}`, data2send)
            cart_request = await private_server.get(`/cart/me/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
            
        }else{
            data2send.quantity = quantity
            await public_server.post(`/lineitem/public/${id_cart}`, data2send)
            cart_request = await public_server.get(`/cart/public/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }
    }else{
        console.log('Need to patch a line item')
        const {id_line_item} = line_item
        delete data2send.product_id
        data2send.quantity = quantity
        console.log(data2send)
        if(user){
            if(quantity > 0) await private_server.patch(`/lineitem/me/${id_cart}/${id_line_item}`, data2send)
            if(quantity === 0) await private_server.delete(`/lineitem/me/${id_cart}/${id_line_item}`)
            cart_request = await private_server.get(`/cart/me/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }else{
            if(quantity > 0) await public_server.patch(`/lineitem/public/${id_cart}/${id_line_item}`, data2send)
            if(quantity === 0) await public_server.delete(`/lineitem/public/${id_cart}/${id_line_item}`)
            cart_request = await public_server.get(`/cart/public/${id_cart}`)
            updated_cart = cart_request.data.data.cart_data
        }
        
    }

    return updated_cart 
}
