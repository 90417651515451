import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';
import useUser from '../hooks/api/useUser';
// utils
import { private_server, public_server } from '../utils/axios';
import { ROLES, USER_TYPES } from '../utils/enums';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  pharmacy: null,
  fullaccess:false,
  isdoctor:false
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      pharmacy: action.payload.pharmacy,
      fullaccess: action.payload.fullaccess,
      isdoctor:action.payload.isdoctor
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      pharmacy: action.payload.pharmacy,
      fullaccess: action.payload.fullaccess,
      isdoctor:action.payload.isdoctor
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      pharmacy: null,
      fullaccess: null,
      isdoctor:false
    };
  }
  if (action.type === 'REFRESH') {
    return {
      ...state,
      user: action.payload.user,
      pharmacy: action.payload.pharmacy,
      fullaccess: action.payload.fullaccess,
      isdoctor:action.payload.isdoctor

    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const userManager = useUser()

  const initialize = useCallback(async () => {
    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : '';
      //console.log(accessToken)
      let fullaccess = false

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user_response = await private_server.get('/users/me')
        const {user} = user_response.data.data
        let pharmacy = null 
        if(user.main_role_id === ROLES.DOCTOR){
          pharmacy = await userManager.actions.onGetPharmacy()
        }
        if(user.main_role_id === ROLES.PHARMACY_ASSISTANT){
          pharmacy = await userManager.actions.onGetAssistantPharmacy()
        }

        const _userroles_rq = await private_server.get('/userrole/list/me')
        const user_roles = _userroles_rq.data.data.user_roles ?? []

        

        let hasAccess = false
        let isdoctor = false

        for(let i=0; i<user_roles.length; i++){
          const current = user_roles[i]
          if(current?.role?.toLowerCase() === 'asistente de ventas' || current?.role?.toLowerCase() === 'doctor' || current?.role?.toLowerCase() === 'pharmacy assistant'){
            hasAccess = true
          }
          if(current?.role?.toLowerCase() === 'doctor'){
            fullaccess = true
          }
          if(current?.role?.toLowerCase() === 'doctor'){
            isdoctor = true
          }
        }

        if(hasAccess){
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: true,
              user,
              pharmacy,
              fullaccess, 
              isdoctor
            },
          });
        }else{
          logout()
        }

        
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
            pharmacy: null,
            isdoctor: false,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
          pharmacy: null,
          isdoctor: false,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = async (data) => {
    const response = await public_server.post('/login', data);

    const { token, user_id, user_type_id} = response.data.data;
    let fullaccess = false

    //console.log('USER ID', user_id)
    if(user_type_id !== USER_TYPES.ADMIN){
      throw {
        message:'Usuario sin privilegios de acceso'
      }
    }

    setSession(token);
    const user_response = await private_server.get('/users/me')
    const {user} = user_response.data.data
    console.log('ESTE ES MI USUARIO', user)

    const _userroles_rq = await private_server.get('/userrole/list/me')
    const user_roles = _userroles_rq.data.data.user_roles ?? []

    let hasAccess = false
    let isdoctor = false

    console.log('User roles', user_roles)

    for(let i=0; i<user_roles.length; i++){
      const current = user_roles[i]
      if(current?.role?.toLowerCase() === 'asistente de ventas' || current?.role?.toLowerCase() === 'doctor' || current?.role?.toLowerCase() === 'pharmacy assistant'){
        hasAccess = true
      }
      if(current?.role?.toLowerCase() === 'doctor'){
        fullaccess = true
      }
      if(current?.role?.toLowerCase() === 'doctor'){
        isdoctor = true
      }
    }

    let pharmacy = null
    if(user.main_role_id === ROLES.DOCTOR){
      pharmacy = await userManager.actions.onGetPharmacy()
    }
    if(user.main_role_id === ROLES.PHARMACY_ASSISTANT){
      pharmacy = await userManager.actions.onGetAssistantPharmacy()
    }
  
    if(hasAccess){
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          pharmacy,
          fullaccess,
          isdoctor
        },
      })
    }else{
      logout()
      throw {
        message:'Usuario sin privilegios de acceso'
      }
    }

    /*dispatch({
      type: 'LOGIN',
      payload: {
        user,
        pharmacy
      },
    })*/;
    
//
    setSession(token);
    //const user_request = await private_server(`/`)
//
    
  };

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await public_server.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  };

  const refreshUser = async() => {
    const user_response = await private_server.get('/users/me')
    const {user} = user_response.data.data

    let pharmacy = null
    if(user.main_role_id === ROLES.DOCTOR){
      pharmacy = await userManager.actions.onGetPharmacy()
    }
    if(user.main_role_id === ROLES.PHARMACY_ASSISTANT){
      pharmacy = await userManager.actions.onGetAssistantPharmacy()
    }

    dispatch({
      type: 'REFRESH',
      payload: {
        user,
        pharmacy
      },
    });
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        refreshUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
