import { private_server, public_server } from '../../utils/axios';
import { CURRENCY } from '../../utils/enums';


const useProduct = () => {   

    const actions = {
        onSearch: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            console.log('Inner params', params)
            let url = `/product/search/currency/2`
            if(params) url = `${url}${params}`
            const request = await public_server.get(url)
            console.log(request)
            result.data = request.data.data.products
            result.count = request.data.data.count
            return result
        },
        onGetCart: async(cartid) => {
            let result = null
            const request = await private_server.get(`/cart/${cartid}`)
            result = request.data.data.cart_data
            return result
        },
        onAddTemplate: async() => {
            let result = null
            const request = await private_server.post('/cart/template/me', {currency_id: CURRENCY.MXN})
            result = request.data.data
            return result
        },
        onEditUser: async(id, data2send) => {
            await private_server.patch(`/pharmacy/doctor/me/user/${id}`, data2send)
        },
    }

    return {
        actions
    }
}
 
export default useProduct;