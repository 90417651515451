import { generic_server, private_server } from '../../utils/axios';
import { fileNameByUrl, fileTypeByUrl } from '../../utils/files';


const useUser = () => {   

    const actions = {
        onGetAll: async(params) => {
            let result = {
                data:[], 
                count:0
            }
            const request = await private_server.get('/pharmacy/doctor/me/users', params)
            result.data = request.data.data.pharmacy_users
            result.count = request.data.data.count
            return result
        },
        onGetUser: async(id) => {
            let result = null
            const request = await private_server.get(`/pharmacy/doctor/me/user/${id}`)
            result = request.data.data.pharmacy_user
            return result
        },
        onGetPharmacy: async() => {
            let result = null
            const request = await private_server.get(`/pharmacy/doctor/me`)
            result = request.data.data.pharmacy_data
            return result
        },
        onGetAssistantPharmacy: async() => {
            let result = null
            const userpharmacy_rq = await private_server.get('/pharmacyuser/list/me')
            const pharmacy_user = userpharmacy_rq.data.data.pharmacy_users[0]
            const request = await private_server.get(`/pharmacy/${pharmacy_user?.pharmacy_id}`)
            result = request.data.data.pharmacy_data
            return result
        },
        onAddUser: async(data2send) => {
            let result = null
            const request = await private_server.post('/pharmacy/doctor/me/user', data2send)
            result = request.data.data
            return result
        },
        onEditUser: async(id, data2send) => {
            await private_server.patch(`/pharmacy/doctor/me/user/${id}`, data2send)
        },
        onUpdatePassword: async(data2send) => {
            await private_server.post(`/users/me/passwordchange`, data2send)
        },
        onDeleteUser: async(id) => {
            await private_server.delete(`/pharmacy/doctor/me/user/${id}`)
        },
        onUpdatePicture: async(id, file) => {
            console.log('Mi archivo', file)
            const fType = fileTypeByUrl(file.name)
            const data2send = {type:fType}
            const options = {headers: {'Content-Type': file.type}}
            const response = await private_server.post(`/users/${id}/image`, data2send)
            console.log(response.data.data)
            await generic_server.put(response.data.data.upload_url, file, options);
        },

    }

    return {
        actions
    }
}
 
export default useUser;