import React from 'react';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { onGetProductItemPrice } from '../../../utils/platform';
import { onGetCurrencyValue } from '../../../utils/currencies';
import { PRODUCT_DISCOUNT_TYPES } from '../../../utils/enums';
import { makeStyles } from '@mui/styles';


const PriceContainer = ({product, settings, cart, showFreeItems}) => {

    const classes = useStyles()
    const line_items = cart?.line_items??[]
    let price_data = onGetProductItemPrice(product)

    const large = settings?.large ?? false

    const priceSize = large ? 'h6' : 'subtitle1'
    const prevPriceSize = large ? 'body1' : 'body2'


    const _lineProduct = line_items.find(el => el.product_id === (product?.id_product || product?.product_id))
    if(_lineProduct){
        if(_lineProduct.sale === 1 && _lineProduct.sale_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){
            console.log(_lineProduct)
            price_data.free = _lineProduct.sale_bundle_included_free
        }
    }
    


    return ( 
        <div className={classes.price_container}>
            <Typography variant={priceSize} style={{fontWeight:600}}>{onGetCurrencyValue(price_data.current)}</Typography>
            {price_data.previous ? <Typography variant={prevPriceSize} className={classes.old_price}>{onGetCurrencyValue(price_data.previous)}</Typography> : null}
            {price_data.free && showFreeItems ? <Typography variant={prevPriceSize} className={classes.free_text}>{`+${price_data.free} Gratis`}</Typography> : null}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    price_container:{
        display:'flex',
        alignItems:'center'
    },
    old_price:{
        color:grey[500],
        textDecoration:'line-through',
        marginLeft:8,
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.750rem'
        }
    },
    free_text:{
        color:theme.palette.primary.main,
        marginLeft:8
    }
}))


export default PriceContainer;