// @mui
import { Stack, Container } from '@mui/material';

// ----------------------------------------------------------------------

export default function CompactContainer({children}) {

  return (
    <>
      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 500,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          {children}
        </Stack>
      </Container>
    </>
  );
}
