import { CONFIG_SETTINGS } from "../config";
import { isValidDocument } from "./files";

export const getRatio = (ratio = '1/1') => {
    return {
      '4/3': 'calc(100% / 4 * 3)',
      '3/4': 'calc(100% / 3 * 4)',
      '6/4': 'calc(100% / 6 * 4)',
      '4/6': 'calc(100% / 4 * 6)',
      '16/9': 'calc(100% / 16 * 9)',
      '10/2': 'calc(100% / 10 * 2)',
      '10/4': 'calc(100% / 10 * 4)',
      '16/2': 'calc(100% / 16 * 3)',
      '9/16': 'calc(100% / 9 * 16)',
      '21/9': 'calc(100% / 21 * 9)',
      '9/21': 'calc(100% / 9 * 21)',
      '1/1': '100%',
    }[ratio];
}

export const onGetFullImagePath = (src) => {
  const valid = isValidDocument(src)
  if(!valid) return null
  return `${CONFIG_SETTINGS.S3_SERVER_URL}${src}`
}

export const onGetCatalogName = (id, catalog) => {
  let response = '-'
  if(!Boolean(id)) return response
  let temp = catalog.find(el => el.value === id)
  if(temp) response = temp.label
  return response
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));


